import cornerSet from "../assets/images/cornerSet.png";
import diamongBathSet from "../assets/images/diamondBathSet.png";
import dsr from "../assets/images/dsr.png";
import glassSoap from "../assets/images/glassSoap.png";
import glassTumbler from "../assets/images/glassTumbler.png";
import herosectionBg from "../assets/images/hero2.jpg";
import logo from "../assets/images/logo.png";
import platinumBathSet from "../assets/images/platinumBathSet.png";
import squareBathSet from "../assets/images/squareBathSet.png";
import ssr from "../assets/images/ssr.png";
import steelTowelRack from "../assets/images/steelRack.png";
import steelTumblrRod from "../assets/images/steelTumbler.png";
import steelSoapHolder from "../assets/images/steel_soap_holder.png";
import steelTowelRing from "../assets/images/towelRing.png";
import steelTowelRod from "../assets/images/towelRod.png";
import triangleBathSet from "../assets/images/triangleBathSet.png";
import wallHook from "../assets/images/wallHook.png";
// Recursive function to import all files from nested folders
const importAllNested = (r) => {
  let images = {};
  r.keys().forEach((key) => {
    if (key.startsWith("./") && key !== "./index.js") {
      const path = key.substring(2); // Remove './' prefix
      const parts = path.split("/");
      let current = images;
      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          current[part.replace(/\.(png|jpe?g|svg)$/, "")] = r(key);
        } else {
          current[part] = current[part] || {};
          current = current[part];
        }
      });
    }
  });
  return images;
};

// Import all images from the steel soap folder
const imagesSteelSoap = importAllNested(
  require.context("../assets/images/steel soap", true, /\.(png|jpe?g|svg)$/)
);
const tumblerSteel = importAllNested(
  require.context("../assets/images/tumbler", true, /\.(png|jpe?g|svg)$/)
);
const towelRing = importAllNested(
  require.context("../assets/images/towel ring", true, /\.(png|jpe?g|svg)$/)
);
const towelRod = importAllNested(
  require.context("../assets/images/towel rod", true, /\.(png|jpe?g|svg)$/)
);
const towelRack = importAllNested(
  require.context("../assets/images/towelrack", true, /\.(png|jpe?g|svg)$/)
);
const wallHook_ = importAllNested(
  require.context("../assets/images/wallhook", true, /\.(png|jpe?g|svg)$/)
);
const glassTumbler_ = importAllNested(
  require.context("../assets/images/glasstumbler", true, /\.(png|jpe?g|svg)$/)
);
console.log(tumblerSteel, "tumbler");
export default {
  logo,
  herosectionBg,
  glassSoap,
  triangleBathSet,
  diamongBathSet,
  platinumBathSet,
  squareBathSet,
  steelSoapHolder,
  ssr,
  cornerSet,
  dsr,
  steelTowelRack,
  steelTowelRing,
  steelTumblrRod,
  glassTumbler,
  steelTowelRod,
  wallHook,
  ...imagesSteelSoap,
  ...tumblerSteel,
  ...towelRing,
  ...towelRod,
  ...towelRack,
  ...wallHook_,
  ...glassTumbler_,
};
